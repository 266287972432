import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import ContentGrid from '../components/content-grid/content-grid.component';
import Main from '../components/main/main.component';
const WalrusIcon = require('../../static/walrus-3d-roumd.svg');

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <ContentGrid>
      <Main>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1 style={{ margin: '1.5rem' }}>Fishing in uncharted waters.</h1>
          <h2>404: Page not Found</h2>
          <img src={WalrusIcon} alt='Walrus Icon' style={{ maxWidth: 500 }} />
          <p>The catch you are looking for doesn't exist... yet!</p>
        </div>
      </Main>
    </ContentGrid>
  </Layout>
);

export default NotFoundPage;
